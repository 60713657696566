<template>
  <div>
    <!-- Main Banner -->

   <MainBanner hide-enquiry="true" />

    <!-- end Main Banner -->
    <div class="container mx-auto mt-10 text-center">
      <h1 class="pb-6 text-3xl font-semibold text-blue-1 md:text-4xl">
        Message Sending Successful!
      </h1>
      <p class="px-4 text-lg text-gray-600 md:px-0">
        Thank you for your kind interest in Bateriku.com. We
        will get back to you as soon as possible.
      </p>
      <router-link to="/" class="block w-48 mx-auto">
        <p class="block py-2 my-5 text-white rounded-lg bg-green-1">
          Back To Home
        </p>
      </router-link>
    </div>
    <!-- copyRight -->
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue"

export default {
  components: {
   MainBanner
  },
};
</script>

<style></style>
